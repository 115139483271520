<template>
    <div class="team-member" v-bind:class="{ right: right}">
        <div class="team-member-info">
            <h2>
                {{data.name}}
            </h2>
            <p class="bre">
                {{data.breNumber}}
            </p>
            <a :href="`tel:+${data.phone}`" class="phone">{{data.phone}}</a>
            <a :href="`mailto:${data.email}`" class="email">{{data.email}}</a>
            <hr />
            <p class="read-more-short" v-html="data.description">
            </p>
            <p class="read-more-long" v-html="data.readMore" v-if="readMoreOpen">
            </p>
            <a class="btn" @click.prevent="toggleReadmore">{{btnText}}</a>
        </div>
        <div class="image-container">
            <img :src="data.image" :alt="data.name">
        </div>
    </div>
</template>

<script>
export default {
    name:"TeamMember",
    props: ['data','right'],
    data(){
        return{
            readMoreOpen: false,
            btnText: 'More',
        }
    },
    methods:{
        toggleReadmore() {
            this.readMoreOpen = !this.readMoreOpen;
            this.btnText = this.readMoreOpen ? 'Less' : 'More';
        }
    }
}
</script>

<style lang="scss" scoped>
.team-member{
    display:flex;
    margin: 4rem 4rem;
    align-items: center;
    .btn{
        &:hover{
            cursor: pointer;
        }
    }
    .team-member-info{
        width: 60%;
        margin-right: 2rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        h2{
            text-align: right;
            width: 300px;
        }
        .bre{
            font-size: 10px;
            margin: 0;
        }
        a{
            display: block;
            margin: 0;
            color: #000;
        }
        .phone{
            margin-top: 1rem;
        }
        .email{
            margin-bottom: 1rem;
        }
        hr{
            display: block;
            height: 1px;
            border: 0;
            width: 45%;
            border-top: 1px solid rgba(0,0,0,0.25);
            margin-left: auto;
            margin-right: 0;
        }
        p{
            max-width: 400px;
            text-align: right;
        }
    }
    .image-container{
        flex: 1 1;
        padding-left: 2rem;
        img{
            display:block;
            width: 100%;
        }
    }
    &.right{
        flex-direction: row-reverse;
        .team-member-info{
            text-align: left;
            margin-left: 2rem;
            margin-right: 0;
            align-items: flex-start;
            h2{
                text-align: left;
                width: 300px;
            }
            p{
                text-align: left;
            }
            hr{
                margin-right: auto;
                margin-left: 0;
            }
        }
        .image-container{
            padding-left: 0;
            padding-right: 2rem;
        }
    }
}

@media (max-width: $mobile) {
    .team-member{
        flex-direction: column-reverse;
        margin: 2rem 1rem;
        align-items: center;
        .team-member-info{
            width: 100%;
            margin-right: 0;
            align-items: center;
            h2{
                text-align: center;
            }
            hr{
                margin-left: auto;
                margin-right: auto;
            }
            p{
                text-align: center;
            }
        }
        .image-container{
            padding: 0;
            margin: 3rem 2rem;
            img{
                display:block;
                width: 100%;
            }
        }
        &.right{
            flex-direction: column-reverse;
            .team-member-info{
                text-align: center;
                margin-left: 0;
                margin-right: 0;
                align-items: center;
                h2{
                    text-align: center;
                    width: 300px;
                }
                p{
                    text-align: center;
                }
                hr{
                    margin-right: auto;
                    margin-left: auto;
                }
            }
            .image-container{
                padding: 0;
            }
        }
    }
}
</style>
