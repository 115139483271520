<template>
    <div id="Team">
        <div class="testimonial-container">
            <hr class="menu-separator" style="position:relative;top:40px;border-top:1px solid #fff">
            <div v-if="loading">
                <Spinner size="large" message="Loading..." line-fg-color="#000" style="padding: 5rem 0;"/>
            </div>
            <Testimonial :data="testimonial" class="testimonial" v-else/>
        </div>
        <div class="team">
            <div class="page-title">
                <h2>
                    Team
                </h2>
            </div>
            <hr class="menu-separator" >
        </div>
        <TeamMember :data="team[0]"/>
        <TeamMember :data="team[1]" :right="true"/>
    </div>
</template>

<script>
import Testimonial from "../components/Testimonial"
import TeamMember from '../components/TeamMember.vue'
import Spinner from 'vue-simple-spinner'

export default {
    name: "Team",
    components: {
        Testimonial,
        Spinner,
        TeamMember
    },
    data(){
        return{
            title: "Team",
            window: {
                width: 0,
                height: 0
            },
            team: [
                {
                    image: '/images/team-ryan.jpg',
                    breNumber: 'Cal BRE #01859461',
                    name: 'Ryan Sokolowski',
                    phone: '310.344.0898',
                    email: ' ryan@coastalluxeliving.com',
                    description: `As a real estate professional Ryan Sokolowski knows that buying, selling or leasing is one of the most important investments you will ever make. Through his expertise in this field, he creates an experience that is smooth, prompt and successful in every way possible.`,
                    readMore: `His extensive experience in Marina del Rey has made him a vertical living expert for anyone wanting to buy, sell, invest or Lease on Marina Pointe Drive. Ryan has all the knowledge necessary for a successful transaction when it comes to luxury condominiums such as the Azzurra, Cove and Regatta. Ryan has the right knowledge and experience for his clients real estate needs in any market.<br><br>Ryan will help you find the one of your dreams. Through researching your needs first, then presenting the best-matched listings for your consideration. He will guide you in finding a place you will be happy to call home. It is his tireless effort that will show you that no one should compromise when it comes to finding the place you dreamed about.<br><br> His expertise in the West Los Angeles area especially in the Marina del Rey real estate market will help ensure that you get the best price for your property. His proven marketing program will ensure the right buyers see your home. Diligence, enthusiasm and a relaxed demeanor enable Ryan to find opportunity for his clients in any market conditions. His communication skills, professionalism and technical expertise set him apart from the casual Realtor, making him a valuable asset to his professional client base. <br><br>"The best indicator that I’m doing a good job is the loyalty and referrals I receive from my amazing clients!"`
                },
                {
                    image: '/images/team-laith.jpg',
                    breNumber: 'Cal BRE #02046510',
                    name: 'Laith Hoffarth',
                    phone: '618.980.3547',
                    email: ' laith@coastalluxeliving.com',
                    description: `Astute knowledge of the Los Angeles market and results-driven dedication to supporting your real estate needs -- Laith is his clients’ greatest advocate, putting your interests first and pushing the envelope to get you exceptional results with unparalleled service.`,
                    readMore: `He is approachable, trustworthy, and has proudly built a reputation for his tenacious work ethic and warm, down-to-earth ability to connect with his clients on a personal level. <br><br>His extensive industry knowledge combined with insightful acumen for pricing and micro-market trends makes him the ideal agent to get you the very best in Los Angeles.`
                },
            ],
            loading: true,
            testimonial: []
        }
    },
    metaInfo: {
        title: 'Team',
        titleTemplate: '%s | Coastal Luxe',
        meta: [{
            name: 'description',
            content: "Coastal Luxe"
        }]
    },
    mounted:function(){
        this.axios.get(`https://strapi.uptowncreative.io/coastal-luxe-testimonials`)
        .then((response) => {
            this.testimonial = response.data;
            this.loading = false;
        })
    },
    methods:{
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    },
}
</script>

<style lang="scss" scoped>
.testimonial-container{
    background: url("/images/reviews.jpg");
    background-size: cover;
    margin-top: -90px;
    height: calc( 100vh - 90px );
    &::before{
        content: ""; // ::before and ::after both require content
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background-image: linear-gradient(#000, #000);
        opacity: .3;
        border-bottom: 1px solid #353536;
    }
}

@media (max-width: 1080px) {
    .testimonial-container{
        background: url("/images/reviews.jpg");
        background-size: cover;
        height: 100vh;
        margin-top: -60px;
        &::before{
            content: ""; // ::before and ::after both require content
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            background-image: linear-gradient(#000, #000);
            opacity: .3;
            border-bottom: 1px solid #353536;
        }
    }
}


</style>
